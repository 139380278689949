<template>
  <article class="animate-fade-in">
    <div class="text-center block">
      <h1 class="text-2xl tracking-wide font-semibold mb-2 text-center animate-slide-down-fade-in01s">Insufficient wallet balance</h1>
      <div v-if="errorModalData.balance > 0" class="text-slate-100 mt-4 leading-tight animate-slide-down-fade-in04s text-center">
        <p>
          You need {{ neededAmount }} more {{ errorModalData.currencyCode }}
          <span v-if="!errorModalData.hideGasFeeInfo">+ gas to pay for this</span>
        </p>
      </div>
      <div v-else-if="errorModalData.balance?.toString() === '0'" class="text-slate-100 mt-4 leading-tight animate-slide-down-fade-in04s text-center">
        <p v-if="isPlatformBalance">You do not have enough funds in your MetaWin balance.</p>
        <p v-else class="mb-4">
          You do not have enough funds in your wallet on the <span class="text-white font-bold">{{ errorModalData.network || 'Ethereum' }}</span> network.
        </p>
      </div>
      <ButtonButton
        v-if="errorModalData.network && errorModalData.network !== 'Ethereum' && !isPlatformBalance"
        type="button"
        class="block w-full mt-6"
        theme="google"
        @click.prevent.stop="bridge()"
      >
      <div class="flex items-center">
        Bridge {{ errorModalData.currencyCode || 'ETH' }} to
        <SvgIcon
          :icon="errorModalData.network"
          class="mx-1"
          width="16"
          height="16"
          />
        {{ errorModalData.network }}
      </div>
      </ButtonButton>
      <ButtonButton
        v-if="isPlatformBalance"
        type="button"
        class="block w-full mt-12"
        @click.prevent.stop="swap()"
      >
        Deposit Now
      </ButtonButton>
      <ButtonButton
        v-else
        type="button"
        class="block w-full mt-8"
        @click.prevent.stop="close()"
      >
        Close
      </ButtonButton>
    </div>
  </article>
</template>

<script>
import { mapWritableState } from 'pinia';
import BigNumber from 'bignumber.js';
import { useUiStore } from '@/store/ui';
import { useAuthStore } from '@/store/auth';
import { walletModalModeTypes, walletModalSubModeTypes } from '@/types/Wallet';

export default defineComponent({
  name: 'ModalErrorContentInsufficientWalletBalance',
  emits: ['close',],
  computed: {
    ...mapWritableState(useUiStore, ['errorModalData', 'showWalletModal', 'bridgeModalConfig',]),
    neededAmount() {
      if (!this.errorModalData) {
        return 0;
      }

      return new BigNumber(this.errorModalData.amount)
      .minus(this.errorModalData.balance)
      .toNumber();
    },
    isPlatformBalance() {
      if (!this.errorModalData) {
        return true;
      }

      return !!this.errorModalData.isPlatformBalance;
    },
  },
  methods: {
    close() {
      this.$emit('close');
      this.errorModalData = null;
    },
    swap() {
      const uiStore = useUiStore();
      const authStore = useAuthStore();

      this.close();
      if (authStore.wallet) {
        uiStore.openTxModal(walletModalModeTypes[0], walletModalSubModeTypes[0]);
      } else {
        uiStore.openTxModal(walletModalModeTypes[0], walletModalSubModeTypes[1]);
      }
    },
    bridge() {
      const uiStore = useUiStore();
      uiStore.openBridgeModal({
        currencyCode: this.errorModalData.currencyCode,
        fromNetwork: 'Ethereum',
        toNetwork: this.errorModalData.network,
      });
      this.close();
    },
  },
});
</script>
